<template>
  <div class="mobile-preview">
    <v-row no-gutters>
      <v-col cols="12" class="mobile-preview__action">
        <v-btn
          outlined
          color="mobile_editor_button"
          @click="openSendLinkDialog"
        >
          {{ $trans("plugins_mobile_preview_text_button") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="mobile-preview__phone">
        <div
          class="mobile-preview__view"
          :style="{
            width: `${previewConfiguration.width}px`,
            height: `${previewConfiguration.height}px`,
            backgroundImage: `url(${require('@/assets/iphone.png')})`,
          }"
        >
          <div
            class="mobile-preview__view--tabs"
            :style="{
              width: `${previewConfiguration.widthDisplay}px`,
              height: `${previewConfiguration.heightDisplay}px`,
              margin: `${previewConfiguration.marginHeightDisplay}px ${previewConfiguration.marginWidthDisplay}px`,
              borderRadius: `${previewConfiguration.radiusDisplay}rem`,
            }"
          >
            <div
              v-if="displayNavigationBar"
              class="navigation-bars"
              :style="{
                borderTopLeftRadius: `${previewConfiguration.radiusDisplay}rem`,
                borderTopRightRadius: `${previewConfiguration.radiusDisplay}rem`,
                background: config('tenants_mobile_color_primary'),
                height: 30 + `${previewConfiguration.marginHeightDisplay}px`,
              }"
            >
              <span class="navigation-bars__left-button">{{
                displayNavigationBarContent.leftButton
              }}</span>
              <span class="navigation-bars__title">{{
                displayNavigationBarContent.title
              }}</span>
              <span class="navigation-bars__right-button">{{
                displayNavigationBarContent.rightButton
              }}</span>
            </div>
            <component :is="view.component" />
            <div
              v-if="displayTabBar"
              class="tab-bars elevation-3"
              :style="{
                borderBottomLeftRadius: `${previewConfiguration.radiusDisplay}rem`,
                borderBottomRightRadius: `${previewConfiguration.radiusDisplay}rem`,
                padding: `0 ${previewConfiguration.marginWidthDisplay}px`,
              }"
            >
              <div
                v-for="(item, index) in displayTabBarContent"
                :key="index"
                class="tab-bars__item"
                :style="{ padding: `${previewConfiguration.padding}px` }"
                @click="setPreviewView(item.type)"
              >
                <div
                  class="tab-bars__item--icon"
                  :style="checkActiveBarItem(item.type)"
                >
                  <v-img v-if="item.img" contain :src="item.img" />
                </div>
                <span>{{ item.title }}</span>
              </div>
            </div>
          </div>
          <div
            class="mobile-preview__view--notch"
            :style="{ top: `${previewConfiguration.marginHeightDisplay}px` }"
          >
            <v-img
              :src="require('@/assets/notch.png')"
              :height="previewConfiguration.notchHeight"
              contain
            />
          </div>
          <div
            class="mobile-preview__view--notch"
            :style="{ top: `${previewConfiguration.marginHeightDisplay}px` }"
          >
            <v-img
              :src="require('@/assets/notch-details.png')"
              :height="previewConfiguration.notchHeight / 3"
              contain
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import mobileViews, {
  navigationBarDisplayView,
  tabBarDisplayView,
} from "./mobileViews";
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "MobilePreview",
  mixins: [sharedActions, mobilePreviewActions],
  data() {
    return {
      previewConfiguration: {
        width: 400,
        height: 400,
        borderRadius: 2.9,
        padding: 33,
        border: 33,
        widthDisplay: 0,
        heightDisplay: 0,
        radiusDisplay: 0,
        marginWidthDisplay: 0,
        marginHeightDisplay: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPreviewView: "mobileEditor/getPreviewView",
    }),
    displayTabBarContent() {
      return [
        {
          type: mobileViews.SERVICES_VIEW,
          img: require("@/assets/services-mobile.svg"),
          title: this.trans("menu_services"),
        },
        {
          type: mobileViews.ABOUT_VIEW,
          img: require("@/assets/about-mobile.svg"),
          title: this.trans("menu_about_us"),
        },
        {
          type: mobileViews.BOOKING_VIEW,
          img: require("@/assets/schedule-mobile.svg"),
          title: this.trans("menu_bookings"),
        },
        {
          type: mobileViews.PROFILE_VIEW,
          img: require("@/assets/user-mobile.svg"),
          title: this.trans("menu_account"),
        },
      ];
    },
    view() {
      const views = [
        {
          type: mobileViews.HOME_VIEW,
          component: () => import("./view/MobileHomeView.vue"),
        },
        {
          type: mobileViews.SERVICES_VIEW,
          component: () => import("./view/MobileServicesView.vue"),
        },
        {
          type: mobileViews.ABOUT_VIEW,
          component: () => import("./view/MobileAboutUsView.vue"),
        },
        {
          type: mobileViews.BOOKING_VIEW,
          component: () => import("./view/MobileBookingView.vue"),
        },
        {
          type: mobileViews.PROFILE_VIEW,
          component: () => import("./view/MobileProfileView.vue"),
        },
      ];
      return views.find((_) => _.type === this.getPreviewView) || views[0];
    },
    displayNavigationBar() {
      return navigationBarDisplayView.find((_) => _ === this.getPreviewView);
    },
    displayTabBar() {
      return tabBarDisplayView.find((_) => _ === this.getPreviewView);
    },
    displayNavigationBarContent() {
      const content = [
        {
          type: mobileViews.SERVICES_VIEW,
          leftButton: "",
          title: this.trans("menu_services"),
          rightButton: "",
        },
        {
          type: mobileViews.ABOUT_VIEW,
          leftButton: "",
          title: this.trans("menu_about_us"),
          rightButton: "",
        },
        {
          type: mobileViews.BOOKING_VIEW,
          leftButton: "",
          title: this.trans("menu_bookings"),
          rightButton: this.trans("my_bookings_filters"),
        },
        {
          type: mobileViews.PROFILE_VIEW,
          leftButton: "",
          title: this.trans("menu_account"),
          rightButton: "",
        },
      ];
      return content.find((_) => _.type === this.getPreviewView);
    },
  },
  watch: {
    "$vuetify.breakpoint.height": function () {
      this.setPreviewSize();
    },
    "$vuetify.breakpoint.width": function () {
      this.setPreviewSize();
    },
  },
  mounted() {
    this.setPreviewSize();
  },
  methods: {
    ...mapMutations({
      setPreviewViewConfiguration:
        "mobileEditor/SET_PREVIEW_VIEW_CONFIGURATION",
      setPreviewView: "mobileEditor/SET_PREVIEW_VIEW",
    }),
    openSendLinkDialog() {
      this.openDialog({
        type: dialogTypes.SEND_MOBILE_APP_LINK_DIALOG,
        title: this.$trans("send_mobile_app_link_dialog_title"),
        size: dialogSize.SMALL,
      });
    },
    checkActiveBarItem(type) {
      if (type !== this.getPreviewView) return { background: "transparent" };
      return { background: this.config("tenants_mobile_color_primary") };
    },
    setPreviewSize() {
      const imageHeight = 893;
      const imageWidth = 486;
      let imageMarginWidth = 67;
      const imageMarginHeight = 40;
      const imageHeightDisplay = 765;
      const imageWidthDisplay = 352;
      const imageRadiusDisplay = 2.5;
      const imagePadding = 20;
      const imageNotchHeight = 30;
      const rem = 16;
      let height = this.$vuetify.breakpoint.height - 2 * rem;
      if (this.$vuetify.breakpoint.height <= 600) {
        height = 600;
      }
      if (this.$vuetify.breakpoint.height >= 1080) {
        height = 1080;
      }
      const scal = height / imageHeight;
      const width = imageWidth * scal;
      let scalDisplay = imageHeightDisplay / imageHeight;
      this.previewConfiguration.height = height;
      this.previewConfiguration.width = width;
      this.previewConfiguration.heightDisplay = height * scalDisplay;
      scalDisplay = imageWidthDisplay / imageWidth;
      this.previewConfiguration.widthDisplay = width * scalDisplay;
      this.previewConfiguration.marginWidthDisplay = imageMarginWidth * scal;
      this.previewConfiguration.marginHeightDisplay = imageMarginHeight * scal;
      this.previewConfiguration.radiusDisplay = imageRadiusDisplay * scal;
      this.previewConfiguration.padding = imagePadding * scal;
      this.previewConfiguration.notchHeight = imageNotchHeight * scal;
      this.setPreviewViewConfiguration({ ...this.previewConfiguration });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-preview {
  min-height: 500px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--v-mobile_editor_background-base);
}

.mobile-preview__action {
  display: flex;
  justify-content: center;
}

.mobile-preview__phone {
  display: flex;
  justify-content: center;
}

.mobile-preview__col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-preview__view {
  position: relative;
  padding: 0.5rem;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile-preview__view--notch {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-preview__view--tabs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navigation-bars,
.tab-bars {
  width: 100%;
  height: 50px;
  background: white;
  text-align: center;
}

.navigation-bars {
  display: grid;
  grid-template-rows: repeat(1, auto);
  grid-template-columns: repeat(3, 1fr);
  color: white;
  font-weight: 500;
  padding-bottom: 10px;
}

.navigation-bars__left-button {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.navigation-bars__title {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.navigation-bars__right-button {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.tab-bars {
  background: #f9f9f9;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .tab-bars__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .tab-bars__item--icon {
      border-radius: 50%;
      padding: 0.2rem;
    }

    span {
      font-size: 0.5rem;
    }
  }
}

@media (min-width: 960px) {
  .mobile-preview__action {
    justify-content: flex-start;
  }
}
</style>
