import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getConfiguration: "mobileEditor/getConfiguration",
      getTranslations: "mobileEditor/getTranslations",
      getPreviewConfiguration: "mobileEditor/getPreviewConfiguration",
    }),
  },
  methods: {
    config(key) {
      const settings = { ...this.getConfiguration };
      return settings[key];
    },
    trans(key) {
      const translations = { ...this.getTranslations };
      return translations[key];
    },
  },
};
