var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-preview"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mobile-preview__action",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","color":"mobile_editor_button"},on:{"click":_vm.openSendLinkDialog}},[_vm._v(" "+_vm._s(_vm.$trans("plugins_mobile_preview_text_button"))+" ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mobile-preview__phone",attrs:{"cols":"12"}},[_c('div',{staticClass:"mobile-preview__view",style:({
          width: ((_vm.previewConfiguration.width) + "px"),
          height: ((_vm.previewConfiguration.height) + "px"),
          backgroundImage: ("url(" + (require('@/assets/iphone.png')) + ")"),
        })},[_c('div',{staticClass:"mobile-preview__view--tabs",style:({
            width: ((_vm.previewConfiguration.widthDisplay) + "px"),
            height: ((_vm.previewConfiguration.heightDisplay) + "px"),
            margin: ((_vm.previewConfiguration.marginHeightDisplay) + "px " + (_vm.previewConfiguration.marginWidthDisplay) + "px"),
            borderRadius: ((_vm.previewConfiguration.radiusDisplay) + "rem"),
          })},[(_vm.displayNavigationBar)?_c('div',{staticClass:"navigation-bars",style:({
              borderTopLeftRadius: ((_vm.previewConfiguration.radiusDisplay) + "rem"),
              borderTopRightRadius: ((_vm.previewConfiguration.radiusDisplay) + "rem"),
              background: _vm.config('tenants_mobile_color_primary'),
              height: 30 + (_vm.previewConfiguration.marginHeightDisplay) + "px",
            })},[_c('span',{staticClass:"navigation-bars__left-button"},[_vm._v(_vm._s(_vm.displayNavigationBarContent.leftButton))]),_c('span',{staticClass:"navigation-bars__title"},[_vm._v(_vm._s(_vm.displayNavigationBarContent.title))]),_c('span',{staticClass:"navigation-bars__right-button"},[_vm._v(_vm._s(_vm.displayNavigationBarContent.rightButton))])]):_vm._e(),_c(_vm.view.component,{tag:"component"}),(_vm.displayTabBar)?_c('div',{staticClass:"tab-bars elevation-3",style:({
              borderBottomLeftRadius: ((_vm.previewConfiguration.radiusDisplay) + "rem"),
              borderBottomRightRadius: ((_vm.previewConfiguration.radiusDisplay) + "rem"),
              padding: ("0 " + (_vm.previewConfiguration.marginWidthDisplay) + "px"),
            })},_vm._l((_vm.displayTabBarContent),function(item,index){return _c('div',{key:index,staticClass:"tab-bars__item",style:({ padding: ((_vm.previewConfiguration.padding) + "px") }),on:{"click":function($event){return _vm.setPreviewView(item.type)}}},[_c('div',{staticClass:"tab-bars__item--icon",style:(_vm.checkActiveBarItem(item.type))},[(item.img)?_c('v-img',{attrs:{"contain":"","src":item.img}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.title))])])}),0):_vm._e()],1),_c('div',{staticClass:"mobile-preview__view--notch",style:({ top: ((_vm.previewConfiguration.marginHeightDisplay) + "px") })},[_c('v-img',{attrs:{"src":require('@/assets/notch.png'),"height":_vm.previewConfiguration.notchHeight,"contain":""}})],1),_c('div',{staticClass:"mobile-preview__view--notch",style:({ top: ((_vm.previewConfiguration.marginHeightDisplay) + "px") })},[_c('v-img',{attrs:{"src":require('@/assets/notch-details.png'),"height":_vm.previewConfiguration.notchHeight / 3,"contain":""}})],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }